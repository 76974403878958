@font-face {
  font-family: futura;
  src: url(.././res/fonts/Futura-CondensedExtraBold.ttf);
}

@font-face {
  font-family: palatino;
  src: url(.././res/fonts/Palatino-Regular.ttf);
}

@font-face {
  font-family: 'helvetica-neue-hoi';
  src: url(.././res/fonts/HelveticaNeueHOI.ttf);
}

@font-face {
  font-family: helvetica;
  src: url(.././res/fonts/Helvetica-Regular.ttf);
}

@font-face {
  font-family: 'helvetica-neue-medium';
  src: url(.././res/fonts/HelveticaNeue-Medium.ttf);
}

@font-face {
  font-family: 'noto-sans-sc';
  src: url(.././res/fonts/NotoSansSC-Medium.otf) format("opentype");
}

@font-face {
  font-family: trade;
  src: url(.././res/fonts/Trade.ttf);
}

body {
  margin: 0px;
}
