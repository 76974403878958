@font-face {
  font-family: futura;
  src: url("Futura-CondensedExtraBold.92414a2f.ttf");
}

@font-face {
  font-family: palatino;
  src: url("Palatino-Regular.6483a040.ttf");
}

@font-face {
  font-family: helvetica-neue-hoi;
  src: url("HelveticaNeueHOI.dbccbcd3.ttf");
}

@font-face {
  font-family: helvetica;
  src: url("Helvetica-Regular.a2b32c7d.ttf");
}

@font-face {
  font-family: helvetica-neue-medium;
  src: url("HelveticaNeue-Medium.8a99a7ee.ttf");
}

@font-face {
  font-family: noto-sans-sc;
  src: url("NotoSansSC-Medium.761749f5.otf") format("opentype");
}

@font-face {
  font-family: trade;
  src: url("Trade.dff34c19.ttf");
}

body {
  margin: 0;
}

/*# sourceMappingURL=index.eae93e74.css.map */
